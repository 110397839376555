export default (scopes = []) => {
  const permissions = {};
  scopes.map((s, i) => {
    const splitted = s.split(":");
    if (splitted.length === 2) {
      if (!permissions[splitted[0]]) {
        permissions[splitted[0]] = [];
      }
      permissions[splitted[0]].push(splitted[1]);
    }
    return s;
  });
  return permissions;
};
